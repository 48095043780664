import throttle from 'throttle';

export default (callback, options) => {
  options = Object.assign(
    {
      horizontalOnly: false
    },
    options
  );

  let windowWidth = window.innerWidth;

  const resize = () => {
    // ignore iOS UI anim resize event on scroll
    if (options.horizontalOnly) {
      if (window.innerWidth === windowWidth) return;
      windowWidth = window.innerWidth;
    }

    callback();
  };

  window.addEventListener('resize', throttle(resize, 250));
  window.addEventListener('orientationchange', callback);
};
