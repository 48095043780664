import inView from 'in-view';
import parallax from 'parallax';
import transitionEnd from 'transition-end';
import vars from 'variables';
import { $, $$ } from 'query-selector';

export default () => {
  function animateCaseTeaser() {
    const caseTeaser = $$('.js-case-teaser');
    let animIndex = 0;

    for (const el of caseTeaser) {
      inView(el, async visible => {
        if (visible && el.classList.contains('u-anim-hidden')) {
          animIndex++;

          el.style.setProperty(
            'transform',
            `translateY(${vars.browser.wide() ? 3 : 1.5}rem)`
          );
          const forceLayout = el.offsetWidth;

          el.classList.remove('u-anim-hidden');

          el.style.setProperty(
            'transition',
            `opacity 300ms ${vars.ease.fade} ${animIndex *
              100}ms, transform 1000ms ${vars.ease.out} ${animIndex * 100}ms`
          );
          el.style.setProperty('opacity', '1');
          el.style.setProperty('transform', 'translateY(0)');

          await transitionEnd(el, 'transform');

          animIndex--;
        }
      });
    }
  }

  animateCaseTeaser();

  function animateQuotes() {
    const quotes = $$('.js-quote');

    if (vars.browser.wide()) {
      for (const el of quotes) {
        parallax([
          {
            reference: el,
            target: $('.l-quote__image', el),
            transform: [
              {
                type: 'translateY',
                value: 3,
                unit: 'rem'
              }
            ]
          },
          {
            reference: el,
            target: $('.l-quote__text', el),
            transform: [
              {
                type: 'translateY',
                value: -3,
                unit: 'rem'
              }
            ]
          }
        ]);
      }
    }
  }

  animateQuotes();

  function animateGridHl() {
    const hls = $$('.l-grid__hl');

    if (vars.browser.wide()) {
      for (const el of hls) {
        parallax([
          {
            reference: el,
            target: el,
            transform: [
              {
                type: 'translateY',
                value: -10,
                unit: 'rem'
              }
            ]
          }
        ]);
      }
    }
  }

  animateGridHl();

  function animateColumns() {
    const columns = $$('.l-columns');

    if (vars.browser.wide()) {
      for (const el of columns) {
        parallax([
          {
            reference: el,
            target: $('.l-columns__column:nth-child(1)', el),
            transform: [
              {
                type: 'translateY',
                value: -5,
                unit: 'rem'
              }
            ]
          },
          {
            reference: el,
            target: $('.l-columns__column:nth-child(2)', el),
            transform: [
              {
                type: 'translateY',
                value: 5,
                unit: 'rem'
              }
            ]
          }
        ]);
      }
    }
  }

  animateColumns();

  function animateDesignProcessTeaser() {
    const hl = $('.js-design-process-teaser-hl');
    const icon = $('.js-design-process-teaser-icon');
    const colorCloud = $('.js-design-process-teaser-color-cloud');

    if (!hl || !icon || !colorCloud) return;

    parallax([
      {
        reference: hl,
        target: hl,
        transform: [
          {
            type: 'translateY',
            value: vars.browser.wide() ? -3 : -1.5,
            unit: 'rem'
          }
        ]
      },
      {
        reference: icon,
        target: icon,
        transform: [
          {
            type: 'translateY',
            value: vars.browser.wide() ? 3 : 1.5,
            unit: 'rem'
          }
        ]
      },
      {
        reference: colorCloud.parentNode,
        target: colorCloud,
        transform: [
          {
            type: 'translateY',
            value: vars.browser.wide() ? 20 : 10,
            unit: 'rem'
          }
        ]
      }
    ]);
  }

  animateDesignProcessTeaser();

  // const body = document.body;
  // const teaser = $$('.l-case-teaser');
  // for (const el of teaser) {
  //   const rect = el.getBoundingClientRect();
  //   el.addEventListener('mousemove', e => {
  //     let x = (e.clientX - rect.left) / rect.width;
  //     let y = (e.clientY - rect.top) / rect.height;
  //     x = Math.round(x * 100) / 100;
  //     y = Math.round(y * 100) / 100;
  //     el.style.setProperty('--mouse-x', x);
  //     el.style.setProperty('--mouse-y', y);
  //   });
  // }
};
