import vars from 'variables';
import onResize from 'on-resize';
import transitionEnd from 'transition-end';
import { $, $$ } from 'query-selector';

const navWrapper = $('.js-nav-wrapper');
const navToggle = $('.js-nav-toggle');
const navIcon = $('.js-nav-icon');
const navIconActiveClass = 'o-nav-icon--active';
export let navVisible = false;

function showNav() {
  navVisible = true;

  navToggle.setAttribute('aria-expanded', 'true');
  navIcon.classList.add(navIconActiveClass);

  navWrapper.style.setProperty('visibility', 'visible');
  navWrapper.style.setProperty('opacity', 1);
  navWrapper.style.setProperty('transition', `opacity 400ms ${vars.ease.fade}`);
}

export async function hideNav() {
  navVisible = false;

  navToggle.setAttribute('aria-expanded', 'false');
  navIcon.classList.remove(navIconActiveClass);

  navWrapper.style.setProperty('opacity', 0);
  navWrapper.style.setProperty('transition', `opacity 100ms ${vars.ease.fade}`);

  await transitionEnd(navWrapper, 'opacity');
  navWrapper.removeAttribute('style');
}

export default () => {
  navToggle.addEventListener('click', e => {
    if (!navVisible) {
      showNav();
    } else {
      hideNav();
    }
  });

  // Close nav if link is clicked
  navWrapper.addEventListener('click', e => {
    if (navVisible && e.target.tagName === 'A') hideNav();
  });

  // Close nav on resize if viewport > 720px
  onResize(
    () => {
      if (!navVisible || !window.matchMedia('(min-width: 45em)').matches)
        return;

      navVisible = false;
      navToggle.setAttribute('aria-expanded', 'false');
      navIcon.classList.remove(navIconActiveClass);
      navWrapper.removeAttribute('style');
    },
    { horizontalOnly: true }
  );

  // A11Y -> jump back to nav toggle if last anchor
  const lastNavLink = $$('a', navWrapper).pop();
  lastNavLink.addEventListener('keydown', e => {
    if (navVisible && e.keyCode === 9) {
      if (!e.shiftKey) {
        e.preventDefault();
        navToggle.focus();
      }
    }
  });
};
