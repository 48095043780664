import inView from 'in-view';
import onScroll from 'on-scroll';
import onResize from 'on-resize';

export default video => {
  function playVideo(video) {
    if (video.readyState === 4) {
      video.play();
    } else {
      video.addEventListener('canplaythrough', () => {
        video.play();
      });
    }
  }

  // Set defaults
  const options = {
    onlyPause: video.dataset.type && video.dataset.type === 'manual',
    playWhenCompletelyVisible:
      video.dataset.type && video.dataset.type === 'content',
    loop: video.getAttribute('loop') !== null
  };

  let isPlaying = false;

  // Handle play / pause outside of view
  inView(video, visible => {
    if (visible) {
      if (options.onlyPause || options.playWhenCompletelyVisible) return;

      playVideo(video);
      // const playPromise = video.play();

      // if (playPromise !== undefined) {
      //   playPromise.then(() => {}).catch(e => {});
      // }
    } else {
      video.pause();
      isPlaying = false;

      if (!options.loop && !options.onlyPause) video.currentTime = 0;
    }
  });

  // Pause (responsive) video if hidden
  // function pauseHiddenVideo() {
  //   if (!video.paused && video.offsetParent === null) {
  //     video.pause();
  //   }
  // }

  // pauseHiddenVideo();
  // onScroll(pauseHiddenVideo);
  // onResize(pauseHiddenVideo);

  // Intersection Observer does not work here,
  // because video might never be fully visible in viewport
  if (options.playWhenCompletelyVisible && !options.onlyPause) {
    const inViewport = () => {
      if (isPlaying) return;

      const rect = video.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      let isInViewport = false;

      // Depends if video height is larger than window height
      if (video.parentNode.offsetHeight <= window.innerHeight) {
        isInViewport = rect.top >= 0 && rect.bottom <= window.innerHeight;
      } else {
        isInViewport = rect.top <= 0 && rect.bottom >= window.innerHeight;
      }

      if (isInViewport) {
        playVideo(video);
        // const playPromise = video.play();

        // if (playPromise !== undefined) {
        //   playPromise.then(() => {}).catch(e => {});
        // }
        isPlaying = true;
      }
    };

    inViewport();
    onScroll(inViewport);
    onResize(inViewport);
  }
};
