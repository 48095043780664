export default (el, propertyName) => {
  return new Promise(resolve => {
    el.addEventListener('transitionend', function handleTransitionEnd(e) {
      if (e.propertyName === propertyName) {
        el.removeEventListener('transitionend', handleTransitionEnd);
        resolve();
      }
    });
  });
};
