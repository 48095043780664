import { $ } from 'query-selector';

export default () => {
  const skipLink = $('.js-skip-link');
  const main = $('main');

  // iOS bug: main does not receive focus, stays at skip link
  skipLink.addEventListener('click', e => {
    e.preventDefault();

    if (main) {
      main.setAttribute('tabindex', '-1');
      main.focus();
      main.removeAttribute('tabindex', '-1');
    }
  });
};
