import parallax from 'parallax';
import vars from 'variables';
import { $, $$ } from 'query-selector';

export default () => {
  const images = $$('.js-team-image');

  function randomRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  images.forEach((el, i) => {
    let offset = 0;

    if (vars.browser.wide()) {
      offset = randomRange(5, 15) * -1;

      if (i % 2 === 0) {
        offset = randomRange(15, 30) * -1;
      }
    } else {
      offset = randomRange(2, 5) * -1;

      if (i % 3 === 0) {
        offset = randomRange(5, 10) * -1;
      }
    }

    parallax([
      {
        reference: el,
        target: el,
        transform: [
          {
            type: 'translateY',
            value: offset,
            unit: 'rem',
            ease: 'outIn'
          }
        ]
      }
    ]);
  });
};
