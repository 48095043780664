import onScroll from 'on-scroll';
import onResize from 'on-resize';
import { $, $$ } from 'query-selector';

// parallax([
//   {
//     reference: parent,
//     target: el,
//     transform: [
//       {
//         type: 'translateX',
//         value: 200,
//         unit: 'px',
//         ease: 'outIn',
//         from: 'start'
//       }
//     ]
//   }
// ]);

export default elements => {
  const intersectionObserverSupport =
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype &&
    'isIntersecting' in window.IntersectionObserverEntry.prototype;

  function inView(rect) {
    return rect.top <= window.innerHeight && rect.bottom >= 0;
  }

  // quad easing
  const easeIn = t => t * t;
  const easeOut = t => t * (2 - t);
  const easeInOut = t =>
    t < 0.5 ? easeIn(t * 2) / 2 : easeOut(t * 2 - 1) / 2 + 0.5;
  const easeOutIn = t =>
    t < 0.5 ? easeOut(t * 2) / 2 : easeIn(t * 2 - 1) / 2 + 0.5;

  function relativeOffsetY(rect) {
    const offset = rect.top + rect.height;
    const height = window.innerHeight + rect.height;

    let y = offset / height;
    y = Math.min(Math.max(y, 0), 1);

    return y;
  }

  function getPosition(el, force = false) {
    if (el.hasOwnProperty('isIntersecting') && !el.isIntersecting && !force)
      return;
    const rect = el.reference.getBoundingClientRect();

    el.inView = inView(rect);
    if (el.inView || force) el.relativeOffsetY = relativeOffsetY(rect);
  }

  function setPosition(el, force = false) {
    if (el.hasOwnProperty('isIntersecting') && !el.isIntersecting && !force)
      return;
    if (!el.inView && !force) return;

    let transformValues = '';

    for (const t of el.transform) {
      let offset = el.relativeOffsetY;

      if (t.ease === 'in') {
        offset = easeIn(offset);
      } else if (t.ease === 'out') {
        offset = easeOut(offset);
      } else if (t.ease === 'inOut') {
        offset = easeInOut(offset);
      } else if (t.ease === 'outIn') {
        offset = easeOutIn(offset);
      }

      offset = Math.round(offset * 1000) / 1000;

      if (t.from === 'start') {
        // 0 -- 1
        offset = 1 - offset;
      } else if (t.from === 'end') {
        // -1 -- 0
        offset = offset * -1;
      } else {
        // -0.5 -- 0.5
        offset = 0.5 - offset;
      }

      const value = t.value * offset;

      transformValues += `${t.type}(${value}${t.unit})`;
    }

    el.target.style.setProperty('transform', transformValues);
  }

  function parallax(els, force = false) {
    // Skip if reference or target does not exits
    for (const el of els) {
      if (!el.reference || !el.target) return;
    }

    // get position
    for (const el of els) {
      getPosition(el, force);
    }

    // seperated for better perf

    // set position
    for (const el of els) {
      setPosition(el, force);
    }
  }

  function initParallax(els) {
    for (const el of els) {
      // Skip if reference or target does not exits
      if (!el.reference || !el.target) continue;

      // Add Intersection Observer for better perf
      // (only calculate position if el is intersecting)
      if (intersectionObserverSupport) {
        const observer = new IntersectionObserver(
          (entries, observer) => {
            entries.forEach(entry => {
              if (
                entry.isIntersecting === true ||
                entry.intersectionRatio > 0
              ) {
                el.isIntersecting = true;
              } else {
                el.isIntersecting = false;
              }
            });
          },
          {
            rootMargin: '0% 0%'
          }
        );
        observer.observe(el.reference);
      }

      // Smooth jank?
      // const forceLayout = el.offsetWidth; // loop bad for perf, once maybe ok
      // el.target.style.setProperty('transition', 'transform 100ms ease-out');
    }

    // Set init position for all elements,
    // no matter if visible or not (to prevent jumps).
    parallax(els, true);
  }

  initParallax(elements);

  onScroll(() => parallax(elements));
  onResize(() => parallax(elements));
};
