import onScroll from 'on-scroll';
import onResize from 'on-resize';

export default (el, callback, options) => {
  // Set defaults
  options = Object.assign(
    {
      offset: 0,
      threshold: 0
    },
    options
  );

  // Fallback
  let alreadyInView = false;

  const inView = (initial = false) => {
    const rect = el.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    if (
      rect.top <= windowHeight * (1 - options.offset) &&
      rect.bottom >= windowHeight * options.offset
    ) {
      if (initial || !alreadyInView) {
        alreadyInView = true;
        callback(true);
      }
    } else {
      if (initial || alreadyInView) {
        alreadyInView = false;
        callback(false);
      }
    }
  };

  // // Intersection Observer
  // const intersectionObserverSupport =
  //   'IntersectionObserver' in window &&
  //   'IntersectionObserverEntry' in window &&
  //   'intersectionRatio' in window.IntersectionObserverEntry.prototype &&
  //   'isIntersecting' in window.IntersectionObserverEntry.prototype;

  // if (intersectionObserverSupport) {
  //   const observer = new IntersectionObserver(
  //     (entries, observer) => {
  //       entries.forEach(entry => {
  //         if (entry.isIntersecting === true || entry.intersectionRatio > 0) {
  //           callback(true);
  //         } else {
  //           callback(false);
  //         }
  //       });
  //     },
  //     {
  //       rootMargin: `0% 0% ${-options.offset * 100}%`,
  //       threshold: options.threshold
  //     }
  //   );
  //   observer.observe(el);
  // } else {
  // Fallback
  inView(true);
  onScroll(inView);
  onResize(inView);
  // }
};
