// must be set before lazyload import
window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.init = false;

import 'lazysizes';
import 'lazysizes-optimumx';
import inView from 'in-view';
import onResize from 'on-resize';
import controlVideo from 'control-video';
import { $, $$ } from 'query-selector';

export default () => {
  const saveData =
    'connection' in navigator && navigator.connection.saveData === true;

  // Lazyload images
  function initLazySizes() {
    // Show lower resolution images if saveData
    if (saveData) {
      const images = $$('.lazyload');

      for (const image of images) {
        image.dataset.optimumx = 1;
      }
    }

    // Remove bg to avoid artefacts
    document.addEventListener('lazyloaded', e => {
      e.target.parentNode.classList.add('o-figure__wrapper--transparent');
    });

    lazySizes.init();
  }

  initLazySizes();

  // Lazyload videos
  const reducedMotion = window.matchMedia('(prefers-reduced-motion)').matches;
  // const lowBattery = ... could be an option
  // const slowConnection = .. could also be an option

  function showVideo(video) {
    video.classList.remove('video-lazyloading');
    video.classList.add('video-lazyloaded');

    // Remove bg to avoid artefacts
    video.parentNode.classList.add('o-figure__wrapper--transparent');
  }

  function setVideoFallback(video) {
    // If content video, set controls
    if (video.dataset.type === 'content') {
      video.setAttribute('controls', 'true');
      video.dataset.type = 'manual';

      // Set poster
      video.setAttribute(
        'poster',
        saveData ? video.dataset.posterlowres : video.dataset.poster
      );
    }
    // If background video, remove sources to cancel load
    else if (video.dataset.type === 'background') {
      // Set poster
      video.setAttribute(
        'poster',
        saveData ? video.dataset.posterlowres : video.dataset.poster
      );

      const sources = $$('source', video);
      for (const source of sources) {
        source.parentNode.removeChild(source);
      }

      video.load();
    }

    // Fade in video
    showVideo(video);
  }

  function initVideoLazyload(videos) {
    for (const video of videos) {
      // Skip if already loading or loaded
      // or if video is hidden i.e. responsive video
      // or if video already has lazyload observer
      if (
        !video.classList.contains('video-lazyload') ||
        video.offsetParent === null ||
        video.hasLazyloadObserver === true
      )
        continue;

      // Make sure video lazyload is only initialized once
      video.hasLazyloadObserver = true;

      inView(
        video,
        visible => {
          // If in view, lazyload
          if (visible && video.classList.contains('video-lazyload')) {
            // Switch classes
            video.classList.remove('video-lazyload');
            video.classList.add('video-lazyloading');

            // Set sources and reload video with new sources
            const sources = $$('source', video);
            for (const source of sources) {
              source.setAttribute('src', source.dataset.src);
            }
            video.load();

            // If data saver or reduced motion, only set poster
            if (saveData || reducedMotion) {
              setVideoFallback(video);
            } else {
              // Fade in video as soon as first frame is loaded
              video.addEventListener('loadeddata', () => {
                showVideo(video);
              });

              // Handle autoplay / fallback after timeout
              const canPlayPromise = new Promise(resolve => {
                video.addEventListener('canplaythrough', () => {
                  // resolve('canplaythrough');
                  controlVideo(video);
                });
              });

              // const timeoutPromise = new Promise(resolve => {
              //   setTimeout(() => {
              //     resolve('timeout');
              //   }, 10000);
              // });

              // Promise.race([canPlayPromise, timeoutPromise]).then(state => {
              //   if (state === 'canplaythrough') {
              //     // Play video if in view, pause video if out of view
              //     controlVideo(video);
              //   } else {
              //     // Set fallback if cannot autoplay b/c probably slow connection
              //     setVideoFallback(video);
              //     controlVideo(video);
              //   }
              // });
            }
          }
        },
        { offset: -0.2 }
      );
    }
  }

  const videos = $$('.video-lazyload');

  initVideoLazyload(videos);

  onResize(() => {
    initVideoLazyload(videos);
  });

  // Look for dynamically added videos
  if (window.MutationObserver) {
    const observer = new MutationObserver(mutations => {
      const newVideos = [];

      mutations.forEach(mutation => {
        const addedNodes = Array.prototype.slice.call(mutation.addedNodes);

        // Search every added node for video
        for (const el of addedNodes) {
          const videos = $$('.video-lazyload', el);

          for (const video of videos) {
            newVideos.push(video);
          }
        }
      });

      // If new videos exist, init video lazyload
      if (newVideos.length > 0) {
        initVideoLazyload(newVideos);

        onResize(() => {
          initVideoLazyload(newVideos);
        });
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  }
};
