import onResize from 'on-resize';
import onScroll from 'on-scroll';
import throttle from 'throttle';
import transitionEnd from 'transition-end';
import vars from 'variables';
import { navVisible, hideNav } from 'nav';
import { $, $$ } from 'query-selector';

export default () => {
  const header = $('.js-header');
  const stickyClass = 'l-header--sticky';
  let threshold = getThreshold();

  function getThreshold() {
    const homeHero = $('.js-home-hero');
    const caseHero = $('.js-case-hero');
    const aboutHero = $('.js-about-hero');

    if (homeHero) return homeHero.offsetHeight;
    else if (caseHero) return caseHero.offsetHeight;
    else if (aboutHero) return aboutHero.offsetHeight;
    else return window.innerHeight;
  }

  async function stickyHeader() {
    let scrollY = window.pageYOffset;

    if (!header.classList.contains(stickyClass) && scrollY > threshold) {
      if (navVisible) hideNav();

      header.classList.add(stickyClass);

      // fade in header
      header.style.setProperty('opacity', '0');
      header.style.setProperty('transform', 'translateY(-25%)');

      const forceLayout = header.offsetWidth;
      header.style.setProperty('opacity', '1');
      header.style.setProperty('transform', 'translateY(0)');

      header.style.setProperty(
        'transition',
        `opacity 200ms ${vars.ease.fade}, transform 300ms ${vars.ease.out}`
      );
    } else if (header.classList.contains(stickyClass) && scrollY < threshold) {
      if (navVisible) hideNav();

      // fade out header
      header.style.setProperty('opacity', '0');
      header.style.setProperty('transform', 'translateY(-25%)');

      header.style.setProperty(
        'transition',
        `opacity 100ms ${vars.ease.fade}, transform 200ms ${vars.ease.out}`
      );

      await transitionEnd(header, 'transform');

      // reset header
      header.style.setProperty('opacity', '1');
      header.style.setProperty('transform', 'translateY(0)');
      header.style.setProperty('transition', `opacity 300ms ${vars.ease.fade}`);
      header.classList.remove(stickyClass);
    }
  }

  stickyHeader();
  onScroll(stickyHeader);

  onResize(() => {
    stickyHeader();
    threshold = getThreshold();
  });
};
