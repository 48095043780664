export default {
  ease: {
    in: 'cubic-bezier(0, 0, 0.2, 1)',
    out: 'cubic-bezier(0.25, 0.5, 0, 1)',
    inOut: 'cubic-bezier(0.6, 0, 0, 1)',
    inOutBack: 'cubic-bezier(0.95, 0.01, 0.4, 1.18)',
    fade: 'cubic-bezier(0, 0, 0.25, 1)'
  },
  browser: {
    legacy:
      typeof Promise === 'undefined' ||
      Promise.toString().indexOf('[native code]') === -1,
    reducedMotion: window.matchMedia('(prefers-reduced-motion)').matches,
    wide: () => window.matchMedia(`(min-width: ${720 / 16}em)`).matches // resize can change value
  }
};
