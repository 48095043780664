import inView from 'in-view';
import vars from 'variables';
import { $, $$ } from 'query-selector';

export default async () => {
  const linesWrapper = $('.js-integration-lines');
  if (!linesWrapper) return;

  const line1 = $('polyline:nth-child(5)', linesWrapper);
  const line2 = $('line:nth-child(6)', linesWrapper);
  const line3 = $('line:nth-child(7)', linesWrapper);
  const line4 = $('line:nth-child(8)', linesWrapper);
  const images = $$('.js-integration-image img');

  // wait for the image to load before showing the svg lines
  const loadPromises = images.map(el => {
    return new Promise(resolve => {
      el.addEventListener('load', resolve);
    });
  });

  await Promise.race(loadPromises);

  linesWrapper.style.setProperty('opacity', 1);

  let hasRun = false;

  inView(
    linesWrapper,
    visible => {
      if (visible && !hasRun) {
        hasRun = true;

        line1.style.setProperty('stroke-dashoffset', '381.09454345703125');

        setTimeout(() => {
          line2.style.setProperty('stroke-dashoffset', '617.9482421875');
          line3.style.setProperty('stroke-dashoffset', '267.3667907714844');
          line4.style.setProperty('stroke-dashoffset', '120.066650390625');
        }, 1000);
      }
    },
    {
      offset: 0.8
    }
  );
};
