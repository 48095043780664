import transitionEnd from 'transition-end';
import vars from 'variables';
import onResize from 'on-resize';
import { $ } from 'query-selector';

export default () => {
  const button = $('.js-home-hero-button');
  const previewWrapper = $('.js-home-hero-preview');
  const previewVideoSmall = $(
    '.js-home-hero-preview > * > *:nth-child(1) video'
  );
  const previewVideoLarge = $(
    '.js-home-hero-preview > * > *:nth-child(2) video'
  );
  const videoWrapper = $('.js-home-hero-video');
  const videoSmall = $('.js-home-hero-video > * > *:nth-child(1) video');
  const videoLarge = $('.js-home-hero-video > * > *:nth-child(2) video');

  // failsafe
  if (
    !button ||
    !videoSmall ||
    !videoLarge ||
    !previewVideoSmall ||
    !previewVideoLarge
  )
    return;

  function playVideo(video) {
    if (video.readyState === 4) {
      video.play();
    } else {
      video.addEventListener('canplaythrough', () => {
        video.play();
      });
    }
  }

  function pauseVideo(video) {
    video.pause();
    video.currentTime = 0;
  }

  function fadeIn(el) {
    el.style.setProperty('transition', `opacity 500ms ${vars.ease.fade}`);
    el.style.setProperty('opacity', '1');
    return transitionEnd(el, 'opacity');
  }

  function fadeOut(el) {
    el.style.setProperty('transition', `opacity 500ms ${vars.ease.fade}`);
    el.style.setProperty('opacity', '0');
    return transitionEnd(el, 'opacity');
  }

  let previewHidden = false;

  async function showVideo() {
    if (previewHidden) return;
    previewHidden = true;

    fadeIn(videoWrapper);
    button.classList.remove('o-play-button--visible');

    await fadeOut(previewWrapper);
    previewWrapper.style.setProperty('visibility', 'hidden');

    // pause preview, play video
    if (window.matchMedia(`(min-width: ${480 / 16}em)`).matches) {
      pauseVideo(previewVideoLarge);
      playVideo(videoLarge);
    } else {
      pauseVideo(previewVideoSmall);
      playVideo(videoSmall);
    }
  }

  // control video module makes sure only one video runs at a time
  async function hideVideo() {
    previewHidden = false;

    fadeOut(videoWrapper);

    previewWrapper.style.setProperty('visibility', 'visible');
    await fadeIn(previewWrapper);

    // pause video, play preview
    if (window.matchMedia(`(min-width: ${480 / 16}em)`).matches) {
      pauseVideo(videoLarge);
      playVideo(previewVideoLarge);
    } else {
      pauseVideo(videoSmall);
      playVideo(previewVideoSmall);
    }

    button.classList.add('o-play-button--visible');
  }

  setTimeout(() => {
    button.classList.add('o-play-button--visible');
  }, 500);

  videoSmall.removeAttribute('controls');
  videoLarge.removeAttribute('controls');

  button.addEventListener('click', showVideo);
  videoSmall.addEventListener('ended', hideVideo);
  videoLarge.addEventListener('ended', hideVideo);

  onResize(() => {
    if (button.classList.contains('o-play-button--visible')) return;

    if (window.matchMedia(`(min-width: ${480 / 16}em)`).matches) {
      pauseVideo(videoSmall);
      playVideo(videoLarge);
    } else {
      pauseVideo(videoLarge);
      playVideo(videoSmall);
    }
  });
};
