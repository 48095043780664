// https://medium.com/@_jh3y/throttling-and-debouncing-in-javascript-b01cad5c8edf

export default (callback, limit = 100) => {
  let inThrottle;
  let lastCallback;
  let lastRan;

  return function() {
    const context = this;
    const args = arguments;
    if (!inThrottle) {
      callback.apply(context, args);
      lastRan = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastCallback);
      lastCallback = setTimeout(function() {
        if (Date.now() - lastRan >= limit) {
          callback.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};
