import inView from 'in-view';
import { $ } from 'query-selector';

export default () => {
  const wrapper = $('.js-ecosystem-reveal');
  if (!wrapper) return;

  const activeClass = 'l-ecosystem__reveal--active';

  inView(
    wrapper,
    visible => {
      if (visible && !wrapper.classList.contains(activeClass)) {
        wrapper.classList.add(activeClass);
      }
    },
    {
      offset: 0.8
    }
  );
};
