// Prevent Safari 10.1 nomodule bug
if (window.scriptLoaded) throw new Error('Safari 10.1 nomodule bug');
window.scriptLoaded = true;

// Set Webpack public path for async modules
const src = document.querySelector('script[data-path]').getAttribute('src');
__webpack_public_path__ = src.substr(0, src.lastIndexOf('/') + 1);

// Polyfills
import 'promise-polyfill'; // for legacy browsers and import()
import 'classlist-polyfill';
import 'focus-visible-polyfill';

// Modules
import initPage from 'init-page';
import lazyload from 'lazyload';
import nav from 'nav';
import skipLink from 'skip-link';
import pageTransition from 'page-transition';
import stickyHeader from 'sticky-header';
import { $ } from 'query-selector';

async function init() {
  lazyload();
  skipLink();
  nav();
  stickyHeader();

  pageTransition();

  // Init page specific modules (also used in pageTransition)
  initPage();

  // Load and execute async
  if ($('meta[name="analytics"]')) {
    const analytics = await import('analytics');
    analytics.default();
  }
}

init();
