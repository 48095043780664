import initPage from 'init-page';
import Swupjs from 'swupjs';
import swupGa from 'swup-ga-plugin';
import swupMergeHead from 'swup-merge-head-plugin';
import transitionEnd from 'transition-end';
import vars from 'variables';
import { $, $$ } from 'query-selector';

export default () => {
  if (vars.browser.legacy || vars.browser.reducedMotion) return;

  function setFocus() {
    const main = $('main');

    if (main) {
      main.setAttribute('tabindex', '-1');
      main.focus();
      main.removeAttribute('tabindex', '-1');
    }
  }

  function fadeOut(el) {
    el.style.setProperty('transition', `opacity 300ms ${vars.ease.fade}`);
    el.style.setProperty('opacity', '0');
    return transitionEnd(el, 'opacity');
  }

  function fadeIn(el, force = true) {
    if (force) {
      el.style.setProperty('opacity', '0');
      const forceLayout = el.offsetWidth;
    }

    el.style.setProperty('opacity', '1');
    el.style.setProperty('transition', `opacity 300ms ${vars.ease.fade}`);
    return transitionEnd(el, 'opacity');
  }

  let headerVisible = false;

  function checkHeaderVisibility() {
    //const logo = $('.js-header-logo');
    //return logo.getBoundingClientRect().bottom > 0;

    // Sticky header, so use magic numbers for MVP :|
    if (vars.browser.wide()) return window.pageYOffset < 120;
    else return window.pageYOffset < 90;
  }

  function handleAnimOut() {
    const anims = [];

    // fade out header if not in viewport
    headerVisible = checkHeaderVisibility();

    if (!headerVisible) {
      const header = $('.js-header');
      anims.push(fadeOut(header));
    }

    // fade out footer
    const footer = $('.js-footer');
    anims.push(fadeOut(footer));

    return anims;
  }

  async function handleAnimIn() {
    const anims = [];
    const header = $('.js-header');

    if (!headerVisible) {
      window.scroll(0, 0);
      anims.push(fadeIn(header, false));
    }

    const footer = $('.js-footer');
    anims.push(fadeIn(footer, false));

    // A11y
    setFocus();

    return anims;
  }

  // Which swup link is clicked – event listener below
  let link = null;

  // Fade page in / out
  const defaultTransition = {
    out: async next => {
      const anims = [];
      const main = $('.js-main');
      anims.push(fadeOut(main));

      const otherAnims = handleAnimOut();

      await Promise.all(anims.concat(otherAnims));
      next();
    },
    in: async next => {
      const anims = [];
      const main = $('.js-main');
      anims.push(fadeIn(main));

      const otherAnims = handleAnimIn();

      await Promise.all(anims.concat(otherAnims));
      next();
    }
  };

  const serviceArticlefromListTransition = {
    out: async next => {
      const anims = [];
      const item = link.parentNode;

      // get vertical offsets
      const list = $('.js-service-list');
      const bodyTop = document.body.getBoundingClientRect().top;
      const listTop = list.getBoundingClientRect().top;
      const itemTop = item.getBoundingClientRect().top;
      let offset = 0;

      headerVisible = checkHeaderVisibility();

      if (headerVisible) {
        // no scroll, so position at top of list
        offset = listTop - itemTop;
      } else {
        // scroll necessary, so position where top of list will be
        offset = Math.abs(bodyTop - listTop);
        offset = offset - itemTop;
      }

      // Insert height placeholder to prevent content jumps ...
      const placeholder = document.createElement('div');
      placeholder.style.setProperty('height', item.offsetHeight + 'px');
      item.parentNode.insertBefore(placeholder, item.nextSibling);

      // ... and take item out of flow
      item.style.setProperty('position', 'absolute');
      item.style.setProperty('width', '100%');
      item.parentNode.style.setProperty('width', 'auto'); // overwrite max-content

      // Get new width of larger headline
      const oldHeight = item.offsetHeight;
      item.classList.remove('o-service-title--list');
      const newHeight = item.offsetHeight;

      // Set new scale ...
      item.style.setProperty('transform', `scale(${oldHeight / newHeight})`);
      item.style.setProperty('transform-origin', 'top left');
      const forceLayout = item.offsetWidth;

      // ... and animate to new position and new scale
      item.style.setProperty('transform', `translateY(${offset}px) scale(1)`);
      item.style.setProperty('transition', `transform 500ms ${vars.ease.out}`);

      anims.push(transitionEnd(item, 'transform'));

      // fade out all inactive list items
      const items = $$('.js-service-list-item > *');
      const inactiveItems = items.filter(el => !el.isSameNode(item));

      for (const el of inactiveItems) {
        anims.push(fadeOut(el));
      }

      const otherAnims = handleAnimOut();

      await Promise.all(anims.concat(otherAnims));
      next();
    },
    in: async next => {
      const items = [];
      const anims = [];

      // Animate other elements in, except headline
      const intro = $('.js-service-intro');
      const content = $('.js-service-content');
      const nav = $('.js-service-nav');
      const related = $('.js-service-related');
      items.push(intro, content, nav, related);

      for (const el of items) {
        anims.push(fadeIn(el));
      }

      const otherAnims = handleAnimIn();

      await Promise.all(anims.concat(otherAnims));
      next();
    }
  };

  let oldNavTop = 0;

  const serviceArticlefromArticleTransition = {
    out: async next => {
      const items = [];
      const anims = [];

      // Store current nav position
      const nav = $('.js-service-nav');

      if (nav.offsetParent !== null) {
        oldNavTop = nav.getBoundingClientRect().top;
      }

      // Animate other elements in, except nav
      const title = $('.js-service-title');
      const intro = $('.js-service-intro');
      const content = $('.js-service-content');
      const related = $('.js-service-related');
      const designProcess = $('.js-service-design-process');
      items.push(title, intro, content, related, designProcess);

      for (const el of items) {
        if (!el) continue;
        anims.push(fadeOut(el));
      }

      const otherAnims = handleAnimOut();

      await Promise.all(anims.concat(otherAnims));
      next();
    },
    in: async next => {
      const items = [];
      const anims = [];

      // Animate other elements in, except nav
      const title = $('.js-service-title');
      const intro = $('.js-service-intro');
      const content = $('.js-service-content');
      const related = $('.js-service-related');
      const designProcess = $('.js-service-design-process');
      items.push(title, intro, content, related, designProcess);

      for (const el of items) {
        if (!el) continue;
        anims.push(fadeIn(el));
      }

      const otherAnims = handleAnimIn();

      // Animate nav to new position
      // important: run after scrollTo()
      const nav = $('.js-service-nav');

      if (nav.offsetParent !== null) {
        const newNavTop = nav.getBoundingClientRect().top;
        const offset = oldNavTop - newNavTop;

        // Animate to new position
        nav.style.setProperty('transform', `translateY(${offset}px)`);
        const forceLayout = nav.offsetWidth;

        nav.style.setProperty('transform', `translateY(0)`);
        nav.style.setProperty('transition', `transform 500ms ${vars.ease.out}`);

        anims.push(transitionEnd(nav, 'transform'));
      }

      await Promise.all(anims.concat(otherAnims));
      next();
    }
  };

  const animations = {
    '*': defaultTransition,
    '*>service-article-from-list': serviceArticlefromListTransition,
    '*>service-article-from-article': serviceArticlefromArticleTransition
  };

  // Init swup and plugins
  const plugins = [swupMergeHead];
  // Only add GA plugin if it is enabled
  if ($('meta[name="analytics"]')) plugins.push(swupGa);

  const swup = new Swupjs({
    LINK_SELECTOR: 'a[data-swup-transition]',
    elements: ['.js-main', '.js-header a', '.js-footer a'],
    animations: animations,
    //animationSelector: '[class*="transition-"]',
    cache: false, // lazyload problem -> current page html is cached, not original state
    pageClassPrefix: '',
    scroll: false,
    debugMode: false,
    preload: true,
    support: true,
    skipPopStateHandling: event => {
      // disable for better browser back button behaviour
      // if (event.state && event.state.source == 'swup') {
      //   return false;
      // }
      // return true;
    },
    animateHistoryBrowsing: false,
    plugins: plugins
  });

  // Set link for anims
  swup.on('clickLink', e => {
    link = e.delegateTarget;
  });

  // Add progress bar
  // const progressBar = $('.js-progress-bar');

  // swup.on('clickLink', () => {
  //   progressBar.removeAttribute('style');
  //   const forceLayout = progressBar.offsetWidth;

  //   progressBar.style.setProperty('transform', 'scaleX(0.66)');
  //   progressBar.style.setProperty('transition', `transform 400ms ease-out`);
  // });

  // swup.on('contentReplaced', async () => {
  //   progressBar.style.setProperty('transform', 'scaleX(1)');

  //   await transitionEnd(progressBar, 'transform');
  //   progressBar.style.setProperty('transform', 'scaleY(0)');
  //   progressBar.style.setProperty('transition', `transform 200ms ease-out`);
  // });

  // if same page reset progressbar
  // swup.on('samePage', () => {
  //   progressBar.removeAttribute('style');
  // });

  // browser back button
  swup.on('popState', e => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  });

  // init page specific modules
  swup.on('contentReplaced', initPage);

  // handle header color
  // swup.on('contentReplaced', async () => {
  //   const header = $('.js-header');
  //   const caseHero = $('.js-case-hero');

  //   if (caseHero && caseHero.classList.contains('l-case-hero--white')) {
  //     // check if white header necessary
  //     if (!header.classList.contains('l-header--white')) {
  //       header.classList.add('l-header--white');

  //       if (headerVisible) {
  //         header.style.setProperty(
  //           'transition',
  //           `color 300ms ${vars.ease.fade}`
  //         );
  //         await transitionEnd(header, 'color');
  //         header.removeAttribute('style');
  //       }
  //     }
  //   } else {
  //     // animate back to blue
  //     if (header.classList.contains('l-header--white')) {
  //       header.classList.remove('l-header--white');

  //       if (headerVisible) {
  //         header.style.setProperty(
  //           'transition',
  //           `color 300ms ${vars.ease.fade}`
  //         );
  //         await transitionEnd(header, 'color');
  //         header.removeAttribute('style');
  //       }
  //     }
  //   }
  // });
};
