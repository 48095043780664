export default callback => {
  let ticking = false;

  const scroll = () => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        callback();
        ticking = false;
      });
    }
    ticking = true;
  };

  window.addEventListener('scroll', scroll);
};
