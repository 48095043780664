import animation from 'animation';
import homeHero from 'home-hero';
import integration from 'integration';
import ecosystem from 'ecosystem';
import team from 'team';
// import colorCloud from 'color-cloud';
import { $ } from 'query-selector';

export default async () => {
  // Animations
  animation();

  // Team list parallax
  team();

  // Hero video with preview on homepage
  homeHero();

  // Integration (factory) svg line animation
  integration();

  // Ecosystem text reveal animation
  ecosystem();

  // bad perf :(
  // colorCloud();

  if ($('.js-map')) {
    const map = await import('map');
    map.default();
  }

  if ($('.js-slider')) {
    const slider = await import('slider');
    slider.default();
  }
};
